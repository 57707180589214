/* TRACE BUTTON */
.burger-menu {
  width: 21px; // Reduced width by 30% from 30px
  height: 18px; // Reduced height by 30% from 26px
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  position: relative;

  .line1, .line2, .line3 {
    width: 100%; // Adjusts to the width of the parent
    height: 2px; // Scaled height by 30% from 3px
    background-color: #fff; // Customize the color of the burger lines
    transition: transform 0.4s ease, opacity 0.4s ease;
    border-radius: 6px;
  }

  &.open {
    .line1 {
      transform: rotate(45deg) translate(4px, 7px); // Scaled down translations
    }
    .line2 {
      opacity: 0; // Hide middle line
    }
    .line3 {
      transform: rotate(-45deg) translate(4px, -7px); // Scaled down translations
    }
  }
}


.offcanvas.show {
  display: block;
}

.circle {
  stroke: #fff;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  animation-delay: 10s;
  animation: CircleOut 0.7s ease-in-out;
  animation-fill-mode: forwards;
}
.linkColor{
    color:#0cf;
}
.playBut {
/*  border: 1px solid red;*/
  display: inline-block;
  -webkit-transition: all 0.5s ease;
  
  .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: #fff;
    transform: translateY(0) 
   }
  
  
  &:hover {
    
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: #fff;
      animation: nudge 0.7s ease-in-out;
      
      @keyframes nudge{
        0% {
          transform: translateX(0)  
        }
        30% {
          transform: translateX(-5px)
        }
        50% {
          transform: translateX(5px)
        }
        70% {
          transform: translateX(-2px)
        }
        100% {
          transform: translateX(0)
        }
      }
    }
    
    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
    
  }
}
/* CHARTS */
.chart{
    width:100%;
}

.chartItem{
    width:100%;
    text-align:left;
     margin-bottom:10px;
     margin-top:15px;
     display:block;
     
    .chartBar{
        display:inline-block;
        max-width:100%;
        height:6px;
        width:100%;
        margin-top:13px;
        border-radius:30px;
        
    }
    .chart1{
        animation: block1 .4s normal forwards;
        animation-delay:.2s;
    }
    .chart2{
        animation: block2 .4s normal forwards;
        animation-delay:.4s;
    }
    .chart3{
        animation: block3 .4s normal forwards;
        animation-delay:.6s;
    }
    .chart4{
        animation: block4 .4s normal forwards;
        animation-delay:.8s;
    }
    .chart5{
        animation: block5 .4s normal forwards;
        animation-delay:1s;
    }
    .chart6{
        animation: block6 .4s normal forwards;
        animation-delay:1.2s;
    }
    .chart7{
        animation: block7 .4s normal forwards;
        animation-delay:1.4s;
    }
    .chart8{
        animation: block8 .4s normal forwards;
        animation-delay:1.6s;
    }
    .chart9{
        animation: block9 .4s normal forwards;
        animation-delay:1.8s;
    }
    .chart10{
        animation: block10 .4s normal forwards;
        animation-delay:2s;
    }
    .chart11{
        animation: block11 .4s normal forwards;
        animation-delay:2.2s;
    }
    .chart12{
        animation: block12 .4s normal forwards;
        animation-delay:2.4s;
    }
    label{
        color:white;
        display:block;
        margin-right:15px;
        line-height:0;
        font-family: 'Open Sans', sans-serif;
        font-weight:800!important;
        font-size:12px!important;
        text-transform:uppercase;
    }
}

@keyframes CircleOut {
  0% { stroke-dashoffset: 650;  opacity: .3; }
  100% { stroke-dashoffset: 0;   opacity: 1;}

}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes block1 {
  0%   {background: #a9d6e5; width: 0; opacity:0}
  100% {background: #a9d6e5; width: 85%; opacity:1}
}
@keyframes block2 {
  0%   {background: #89c2d9; width: 0; opacity:0}
  100% {background: #89c2d9; width: 95%; opacity:1}
}
@keyframes block3 {
  0%   {background: #61a5c2; width: 0; opacity:0}
  100% {background: #61a5c2; width: 90%; opacity:1}
}
@keyframes block4{
  0%   {background: #468faf; width: 0; opacity:0}
  100% {background: #468faf; width: 99%; opacity:1}
}
@keyframes block5 {
  0%   {background: #2c7da0; width: 0; opacity:0}
  100% {background: #2c7da0; width: 90%; opacity:1}
}
@keyframes block6 {
  0%   {background: #2a6f97; width: 0; opacity:0}
  100% {background: #2a6f97; width: 75%; opacity:1}
}
@keyframes block7 {
  0%   {background: #014f86; width: 0; opacity:0}
  100% {background: #014f86; width: 100%; opacity:1}
}
@keyframes block8 {
  0%   {background: #01497c; width: 0; opacity:0}
  100% {background: #01497c; width: 85%; opacity:1}
}
@keyframes block9 {
  0%   {background: #013a63; width: 0; opacity:0}
  100% {background: #013a63; width: 85%; opacity:1}
}
@keyframes block10 {
  0%   {background: #012a4a; width: 0; opacity:0}
  100% {background: #012a4a; width: 85%; opacity:1}
}
@keyframes block11 {
  0%   {background: #011E4A; width: 0; opacity:0}
  100% {background: #011E4A; width: 85%; opacity:1}
}
@keyframes block12{
  0%   {background: #01154A; width: 0; opacity:0}
  100% {background: #01154A; width: 100%; opacity:1}
}

/* OUCH MY EYES */
@-webkit-keyframes argh-my-eyes-text {
  0%   { color: #fff; transform: skewX(-15deg)}
  49% {  color: #fff; transform: skewX(15deg)}
  50% {  color: #000; transform: skewX(-15deg)}
  99% {  color: #000; transform: skewX(15deg)}
  100% {  color: #fff; transform: skewX(0deg)}
}
@-moz-keyframes argh-my-eyes-text {
  0%   {  color: #fff; transform: skewX(-15deg); }
  49% {  color: #fff;transform: skewX(15deg) }
  50% {  color: #000; transform: skewX(-15deg)}
  99% {  color: #000; transform: skewX(15deg)}
  100% {  color: #fff;  transform: skewX(0deg)}
}
@keyframes argh-my-eyes-text {
  0%   {  color: #fff; transform: skewX(-15deg); }
  49% {  color: #000;transform: skewX(15deg) }
  50% {  color: #ff3f95; transform: skewX(-15deg)}
  99% {  color: #ff3f95; transform: skewX(15deg)}
  100% {  color: #000;  transform: skewX(0deg)}
}
  @-webkit-keyframes argh-my-eyes {
    0%   { background-color: #fff; }
    49% {  background-color: #fff; }
    50% {  background-color: #000; }
    99% {  background-color: #000; }
    100% {  background-color: #fff; }
  }
  @-moz-keyframes argh-my-eyes {
    0%   {  background-color: #fff; }
    49% {  background-color: #fff; }
    50% {  background-color: #000; }
    99% {  background-color: #000; }
    100% {  background-color: #fff; }
  }
  @keyframes argh-my-eyes {
    0%   {  background-color: #00ff4e; }
    49% {  background-color: #000; }
    50% {  background-color: #ff3f95; }
    99% {  background-color: #ff3f95; }
    100% {  background-color: #000; }
  }
  
  
  @keyframes zoomin {
          0% { transform: scale(1, 1) }
          50% { transform: scale(1.2, 1.2) }
          100% { transform: scale(1, 1) }
          
  }
  
  /* TV TERMINAL */
  .terminal img[src*="blob:"], .terminal video {
      max-width: 100%;
  }
  #terminal {
      height: 100vh;
  }
  :root {
      --color: red;
      --background: #200606;
      --link-color: #3377FF;
      --glow: 1;
      --font: monospace;
  }
  @media (min-width: 800px) {
      :root {
          --size: 1.2;
      }
  }
  @media (min-width: 1200px) {
      :root {
          --size: 1.4;
      }
  }
  /* to see yourself like in a mirror */
  .self {
      transform: scale(-1, 1);
  }
  
  /* noise solution based on Temani Afif https://codepen.io/t_afif/pen/RwyqLKZ */
  .noise {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1001;
      opacity: 0.1;
      pointer-events: none;
      background: 
          repeating-radial-gradient(#000 0 0.0001%,#fff 0 0.0002%) 50% 0/2500px 2500px,
          repeating-conic-gradient(#000 0 0.0001%,#fff 0 0.0002%) 50% 50%/2500px 2500px;
      background-blend-mode: difference;
      animation: shift .2s infinite alternate;
  }
  @keyframes shift {
      100% {
          background-position: 50% 0, 50% 60%;
      }
  }
  /* flicker animation taken from http://goonhub.com/secret */
  .flicker {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(18, 16, 16, 0.2);
      opacity: 0;
      z-index: 1000;
      pointer-events: none;
      animation: flicker 0.12s infinite;
  }
  @keyframes flicker {
    0% {
      opacity: 0.552;
    }
    5% {
      opacity: 0.48287;
    }
    10% {
      opacity: 0.59134;
    }
    15.0% {
      opacity: 0.79543;
    }
    20% {
      opacity: 0.75134;
    }
    25% {
      opacity: 0.1956;
    }
    30.0% {
      opacity: 0.90687;
    }
    35% {
      opacity: 0.122;
    }
    40% {
      opacity: 0.62254;
    }
    45% {
      opacity: 0.56977;
    }
    50% {
      opacity: 0.9925;
    }
    55.0% {
      opacity: 0.55487;
    }
    60.0% {
      opacity: 0.16607;
    }
    65% {
      opacity: 0.12353;
    }
    70% {
      opacity: 0.2214;
    }
    75% {
      opacity: 0.67908;
    }
    80% {
      opacity: 0.97163;
    }
    85.0% {
      opacity: 0.1275;
    }
    90% {
      opacity: 0.37186;
    }
    95% {
      opacity: 0.24475;
    }
    100% {
      opacity: 0.37221;
    }
  }
  /* based on
  https://blog.carbonfive.com/2015/01/07/vintage-terminal-effect-in-css3/
  */
  .scanlines {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(
          to bottom,
          rgba(255,255,255,0),
          rgba(255,255,255,0) 50%,
          rgba(0,0,0,.2) 70%,
          rgba(0,0,0,.6)
      );
      background-size: 100% .3rem;
      position: fixed;
      pointer-events: none;
  }
  .scanlines:before {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 5px;
    background: #fff;
    background: linear-gradient(to bottom,
        rgba(255,0,0,0) 0%,
        rgba(255,250,250,1) 50%,
        rgba(255,255,255,0.98) 51%,
        rgba(255,0,0,0) 100%
    ); /* W3C */
    opacity: .1;
  }
  .scanlines:after {
    box-shadow: 0 2px 6px rgba(25,25,25,0.2),
        inset 0 1px rgba(50,50,50,0.1),
        inset 0 3px rgba(50,50,50,0.05),
        inset 0 3px 8px rgba(64,64,64,0.05),
        inset 0 -5px 10px rgba(25,25,25,0.1);
  }
  #terminal:focus-within ~ .scanlines:before {
      content: '';
      display: block;
      animation: vline calc(var(--time, 2) * 1s) linear infinite;
  }
  /*
   * MS Edge don't support focus-within and css vars
   * inside pseudo selector
   */
  @supports (-ms-ime-align:auto) {
      .scanlines:before {
          content: '';
          animation: vline 3s linear infinite;
      }
  }
  @keyframes vline {
    to { transform: translate(0, 100vh)}
  }
  /* turn off animation */
  .tv {
      height: 100vh;
      position: relative;
  }
  .tv.collapse {
      animation: size 2s ease-out;
      animation-fill-mode: forwards;
  }
  .tv.collapse:before {
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: white;
      z-index: 1;
      opacity: 0;
      animation: opacity 2s ease-out;
      animation-fill-mode: forwards;
  }

  @keyframes opacity {
      to { opacity: 1; }
  }
  @keyframes size {
      50% {
          transform: scaleX(calc(1 / var(--width)));
          opacity: 1;
      }
      98% {
          transform: scaleX(calc(1 / var(--width))) scaleY(calc(1 / var(--height)));
          opacity: 1;
      }
      100% {
          transform: scaleX(calc(1 / var(--width))) scaleY(calc(1 / var(--height)));
          opacity: 0;
      }  
  }
  #terminal {
	  z-index:1002;
      padding-bottom: 36px;
  }
  .collection {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
  }
