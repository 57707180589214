/* This ensures the full screen is a flex container, centering its children */
.vh-100.d-flex {
    text-align: center; /* Centers inline or inline-block elements horizontally */
    width: 100%; /* Ensures it spans the full width of the viewport */
}

.loader {
    display: inline-block; /* Helps with centering inside text-align: center; */
    position: relative;
    width: 200px; /* Adjusted for the loader */
    height: 100px; /* High enough to account for vertical motion */
    margin: auto; /* Helps further with horizontal centering */
    overflow: visible; /* Allows SVG animations to extend beyond the div */
    padding-top: 50px; /* Optional: Adds padding to vertically center the loader */
    z-index: 0;
}

.dune {
    width: 100px; /* Adjust dimensions as necessary */
    height: 39px; /* Match aspect ratio */
    position: absolute;
    right: -33.33%; /* Starting position off-screen to the right */
    opacity: 0;
    transform-origin: center; /* Ensures scaling is centered */
}


@keyframes dune-animation {
    0% {
        transform: translateX(0) scale(0.3);
        opacity: 0;
    }
    20% {
        opacity: .35;
    }
    50% {
        transform: translateX(-100px) scale(1.3); // Reduce translation to make dunes closer
        opacity: .8;
    }
    62.5% {
        transform: translateX(-112px) scale(.9); // Reduce translation to make dunes closer
        opacity: 1;
    }
    75% {
        transform: translateX(-125px) scale(1); // Reduce translation to make dunes closer
        opacity: .35;
    }
    100% {
        transform: translateX(-170px) scale(0.5); // Slightly beyond the -150px for a smoother exit
        opacity: 0;
    }
}

.dune {
    animation: dune-animation .9s linear infinite; // Significantly increase the speed (3x faster than the original 4s)
    &:nth-child(2) {
        animation-delay: 0.3s; // 50% of the animation duration to bring dunes closer
    }
    &:nth-child(3) {
        animation-delay: 0.6s; // 50% of the animation duration to bring dunes closer
    }
}
