/* This used to work for the parent element of button divs */
/* But it does not work with newer browsers, the below doesn't hide the play button parent div */

*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}
video::-webkit-media-controls {
    opacity: 0;
}
video::-webkit-media-controls-start-playback-button {
  display: none !important;
}
/* IOS 13 */
video::slotted::-webkit-media-controls-container{
    display:none !important;
    visibility: hidden!important;
    opacity: 0 !important;
   -webkit-appearance: none !important;
  }
/* IOS 14 */
:host::shadow::-webkit-media-controls-container{/* hide the element */}

video::-webkit-media-controls-overlay-play-button {
      display: none;
    }
.custom-video-controls {
  z-index: 2147483647;
}
video::-webkit-media-controls {
    display:none !important;
}
video::-webkit-media-controls-panel {

  display: none!important;

  -webkit-appearance: none;

}

video::--webkit-media-controls-play-button {

  display: none!important;

  -webkit-appearance: none;

}

video::-webkit-media-controls-start-playback-button {

  display: none!important;

  -webkit-appearance: none;

}