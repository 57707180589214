body{
    background:#000;
}
body.home-page{
	.carousel-indicators{
		display: none;
	}
}
.navbar {
  z-index: 1050; /* Ensure the navbar has a higher z-index than the offcanvas */
  position: relative;

  .burger-menu {
    z-index: 2001; /* Ensure the burger menu is above everything else */
  }

  .navbar-brand {
    z-index: 2001; /* Ensure the logo is above the offcanvas */
  }

  .offcanvas {
    z-index: 2000; /* Offcanvas should have a lower z-index than the navbar and burger menu */
    opacity: 0
  }
}
/* For most modern browsers */
input::placeholder {
  color: rgba(255,255,255,.4); /* Change the color to your desired value */
}

/* For Mozilla Firefox */
input:-moz-placeholder {
  color: rgba(255,255,255,.4); 
}

/* For Internet Explorer */
input:-ms-input-placeholder {
  color: rgba(255,255,255,.4); 
}

/* For Microsoft Edge */
input::-ms-input-placeholder {
  color: rgba(255,255,255,.4); 
}

/* For Safari, Chrome, and Opera */
input::-webkit-input-placeholder {
  color: rgba(255,255,255,.4); 
}
.App {
  background:#000;
}
.Typewriter span{
        text-shadow: 0 0 40px #0cf, 0 0 10px #0cf;
}
.playBut{
    position:absolute;
    z-index:1000;
    top:30%;
    left:30%;
}
.lead{
      font-family: 'Open Sans', sans-serif;
      font-weight:600;
}
.homeContent .carousel-indicators{
   // display:none;
   z-index: 201!important;
}
.container-fluid{
    padding-left:0!important;
    padding-right:0!important;
}
.actionBtn {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: .8125em;
  font-style: normal;
  text-align: left;
  padding: 10px 20px;
  background: rgba(#000000, .7);
  color: white;
  display: block;
  max-width: 150px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
  text-decoration: none;
  border: 1px solid rgba(255, 255, 255, .25);
  position: relative;
  transition: border-radius 0.15s ease-in-out, border 0.3s ease-in-out;
  z-index: 180;
  &:hover {
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border: 1px solid rgba(255, 255, 255, .45);
  }

  .arrowBtn {
    display: block;
    position: absolute;
    top: 17px;
    right: 8px;
    background-color: transparent;
    background-image: url('../images/arrowBtn.svg');
    background-size: 8px 8px;
    background-position: center center;
    width: 8px;
    height: 8px;
    transition: top 0.15s ease-in-out;
  }

  &:hover .arrowBtn {
    top: 8px;
  }
}



.chatGPT{
    //position:absolute;
    //z-index:210;
    color:white;
    //bottom:100px;
    height: auto;
    width: 100%;
    input[type=text]{
        width:100%;
    }
    .conversation{
        display:block;
        height: 100px;
        max-height:100px;
        overflow-y: auto;
        overflow-x: hidden;
        
       
    }
}
/* width */
.conversation{
    padding-right:12px;
}
.conversation::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.conversation::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.conversation::-webkit-scrollbar-thumb {
  background: rgba(#888,.5);
}

/* Handle on hover */
.conversation::-webkit-scrollbar-thumb:hover {
  background: #555;
  
}
.conversation::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}
.mySubmit-btn{
    display:block;
}
 .home-carousel .carousel-control-prev,
 .home-carousel .carousel-control-next{
     display:none;
 }
 .carousel-item .zoom-image{
     animation: 30s ease 0s normal none infinite running zoomin;
      -webkit-animation: 30s ease 0s normal none infinite running zoomin;
      -o-animation: 30s ease 0s normal none infinite running zoomin;
      -moz--o-animation: 30s ease 0s normal none infinite running zoomin;
 }
 .LoaderSpinner{
     width:100%;
     height:100%;
     position:absolute;
 }
 .fade-in-image{
animation: fadeIn 3s;
animation-delay:1s;
 }
 .offcanvas{
 	background-color:rgba(0,0,0, .75);
    color:white;
    backdrop-filter: blur(10px); 
    //border-bottom:1px solid rgba(255,255,255,.08)!important;
 }
 .navbar{
     background-color: transparent;
     width:100%;
     border-bottom:none!important;
     /*@include media-breakpoint-up(lg) { 
           background-color: rgba(0,0,0,.1)!important;
           border-bottom:1px solid rgba(255,255,255,.09)!important;
      }*/
 }
 .badge{
     margin-right:10px;
     font-family: "Lexend", sans-serif;
	 font-optical-sizing: auto;
     font-weight:700;
 }
 .masthead-contact {
   height: 100vh;
   min-height: 500px;
   padding-top:22%;
   @include media-breakpoint-up(sm) { 
       padding-top:15%;
   }
   .lead{
       font-size:14px;
       @include media-breakpoint-up(md) { 
   		    font-size:16px;
   			}
   }
   label{
       font-family: 'Roboto', cursive;
       font-size:16px;
       font-weight:600;
   }
 }
 .masthead-about{
   height: 100vh;
   min-height: 500px;
   background:#070707;
   padding-top:22%;
   @include media-breakpoint-up(sm) { 
       padding-top:15%;
   }
   .lead{
       font-size:13px;
   }
 }
  video, .img-carousel{
      object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
  }

  .img-carousel2{
       // object-fit: cover;
          background-position:center left;
          width: 100vw;
          height: 100vh;
          position: fixed;
          top: 0;
          left: 0;
    }
  
  .home-caption,
  .video-caption{
      position:absolute;
      font-family: 'Playfair Display', serif;
      font-optical-sizing: auto;
      text-align:left;
      color:white;
      width:100%;
      right:15%;
      top:30%;
      z-index:230;
      @include media-breakpoint-up(lg) { 
           width:30%;
       }
  }
  .headline-p{
       font-family: "Lexend", sans-serif;
  }
  .home-caption p,
  .video-caption p{
      font-size:16px!important;
      line-height:168%;
      font-style: normal;
      text-shadow: -10px 5px 20px rgba(0,0,0,0.6);
      @include media-breakpoint-up(md) { 
           font-size:18px!important;
       }
       strong{
	       font-weight: 600
       }
     
  }
  .video-caption{
      position:absolute;
      //background: rgba(0,0,0,.09);
      background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%);
      color:white;
      width:100%;
      right:0;
      bottom:0;
      height:100%;
      top:0;
      @include media-breakpoint-up(lg) { 
      background: linear-gradient(97deg, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 8%, rgba(0,0,0,0.025) 10%, rgba(0,0,0,0.035) 12%, rgba(0,0,0,0.075) 20%, rgba(0,0,0,0.095) 27%, rgba(0,0,0,0.1) 35%, rgba(0,0,0,0.3) 42%, rgba(0,0,0,0.5) 47%, rgba(0,0,0,0.6) 50%, rgba(0,0,0,0.7) 60% ,rgba(0,0,0,0.75) 100%);
          width:65%;
       }
      
  }

  .video-caption .content{
      position:absolute;
      width:74%;
      bottom:10%;
      right:11.5%;
      @include media-breakpoint-up(md) { 
          width:67%;
          right:15.4%;
       }
      @include media-breakpoint-up(lg) { 
          width:57%;
          right:15.4%;
       }
       @include media-breakpoint-up(xl) { 
           width:47%;
           right:15.4%;
        }
  }
  
    .video-caption .content h3{
        font-size:1.25em;
        font-family: "Lexend", sans-serif;
		font-optical-sizing: auto;
		font-weight: 600;
		font-style: normal;
        @include media-breakpoint-up(lg) { 
            font-size:1.5em;
         }
    }
  .animatedNumber{
      color:white;
      font-size:30px;
      width:auto;

  }
  .animatedNumber span{
      font-size:29px;
      font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
      font-weight:700;
      letter-spacing:3px;
  }
    .animatedNumber label{
        font-size:12px;
        font-family: "Lexend", sans-serif;
		font-optical-sizing: auto;
        font-weight:700;
        display:block;
    }
    
    
    .APIResponse{
        overflow: hidden;
    }
    .APIResponse p,
    .conversation,
     .conversation p{
        font-family: var(--bs-font-monospace)!important;
        font-size:14px!important;
        overflow: hidden;
        overflow-wrap: break-word;
         
    }
    .carousel-inner{
        z-index:98;
    }
    .muteBtn{
         position:absolute;
         top:42%;
         left:16%!important;
         z-index:290;
        @include media-breakpoint-up(md) { 
	      position:absolute;
         top:52%;
         left:18%!important;
         z-index:290;
            
        }
        @include media-breakpoint-up(md) { 
	      position:absolute;
         top:35%;
         left:18%!important;
         z-index:290;
            
        }
        @include media-breakpoint-up(lg) { 
	         position:absolute;
            top:36%;
            left:82.5%!important;
            z-index:290;
            
        }
    }
    .bubbleBtn{
        background: rgb(51,51,51);
        background: linear-gradient(90deg, rgba(51,51,51,1) 0%, rgba(59,55,55,1) 37%, rgba(0,0,0,1) 100%);
        background-image:url('../images/arrow.svg');
        background-size:10px 10px;
        background-position:28px center;
        color:white;
        opacity: 0.95;
        filter: alpha(opacity=8);
        fill:#fff;
        background-repeat: no-repeat;
        border:1px solid rgba(#fff, .4);
        border-radius:6px;
        padding:3px 10px;
        width:auto;
        height:auto;
        display:block;
        font-family: 'Roboto', sans-serif!important;
        font-weight:600;
        font-size:16px!important;
        line-height:auto;
        text-align:center;
        text-decoration:none;
        margin:10px auto;
        transition: opacity 0.5s linear 2s; 
        @include media-breakpoint-up(md) { 
             background-size:15px 15px;
             padding:7px 20px;
             font-size:14px;
             line-height:auto;
             margin:20px auto;
         }
    }
    .muted{
        background-color:transparent ;
        background-image:url('../images/unmute.svg');
        background-size:15px 15px;
        background-position:center center;
        opacity: 0.8;
        filter: alpha(opacity=88);
        fill:#fff;
        background-repeat: no-repeat;
        border:1px solid rgba(255,255,255, .4);
        border-radius:60px;
        padding:20px;
        width:20px;
        height:20px;
        display:block;
        transition: opacity 0.5s linear 2s; 
        @include media-breakpoint-up(md) { 
            background-size:20px 20px;
            padding:24px;
            width:24px;
            height:24px;
         }
    }

    .unmuted{
        background-color:transparent ;
        background-image:url('../images/mute.svg');
        background-size:15px 15px;
        background-position:center center;
        opacity: 0.8;
        filter: alpha(opacity=80);
        fill:#fff;
        background-repeat: no-repeat;
        border:1px solid rgba(255,255,255, .4);
        border-radius:60px;
        padding:20px;
        width:20px;
        height:20px;
        display:block;
         transition: opacity 0.5s linear 2s; 
         @include media-breakpoint-up(md) { 
             background-size:20px 20px;
             padding:24px;
             width:24px;
             height:24px;
          }
    }
    .muted svg{
        fill:#fff!important;
    }
    .muted:hover,
    .unmuted:hover,
    .bubbleBtn:hover{
        color:white;
        opacity:1;
        filter: alpha(opacity=100);
        border:1px solid rgba(255,255,255, .85);
    }
.loader-container{
     font-family: "Lexend", sans-serif;
    display:block;
    position:absolute;
    background:#070707;
    font-size:20px;
    color:rgba(255,255,255,.9);
    text-align:center;
    width:100%;
    height:100%;
   /* -webkit-animation: argh-my-eyes .1s infinite;
    -moz-animation:    argh-my-eyes .1s infinite;
    animation:         argh-my-eyes .1s infinite;*/
    
}
.spinner{
    position:relative;
    padding-top:30%;
}
.ml-auto{
    margin-left:auto!important;
}
  #introCarousel,
  .carousel-inner,
  .carousel-item,
  .carousel-item.active {
    height: 100vh;
  }
.offcanvas-body .myMenumd{
      padding-top:30%;
      @include media-breakpoint-up(md) { 
          padding-top:20%;
      }
       @include media-breakpoint-up(md) { 
          padding-top:20%;
      }
      a{
	      display: inline-block!important
      }
  }
  .nav-link{
      font-family: 'Playfair Display', serif;
      font-weight:600!important;
      color: white;
      display: inline-block!important;
      font-size:18px!important;
      text-transform:uppercase;
      letter-spacing:.175em;
      margin:0 0 0 0;
      text-shadow:0 1px 1px rgba(#070707,.3);
      @include media-breakpoint-up(md) { 
	      font-size:30px!important;
	   }
  }
  .navbar-nav .select {
      margin:5px 32px 0 32px;
  }
  .navbar-nav .select select{
      font-family: 'Roboto', sans-serif;
      background:transparent;
      font-size:14px!important;
      letter-spacing:auto;
      color:white;
      border:none;
      outline:none;
      option{
          border: none;
          outline:none;
      }
      &:active *{
          border:none;
      }
      
      
  }
  .carousel{
      z-index:200!important;
  }
  .background-suttle{
      z-index:201;
      background:rgba(0,0,0,0.2);
      width:100%;
      height:100%;
      position:absolute;
  }
  .carousel-item{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    &.active{
        .img-carousel{
            
        }
    }
  }
  .carousel-control-prev,
   .carousel-control-next{
      z-index:99!important;
  }

  .home-carousel{
       .carousel-item{
           .img-carousel{
                @include media-breakpoint-up(md) { 
                    height: 100vh;
                        left: 0;
                        position: fixed;
                        top: 0;
                        width: 100vw;

                       
                }
            }
           
       }
      
  }
  .carousel-item:nth-child(1) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .carousel-item:nth-child(2) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .carousel-item:nth-child(3) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }


  @media (min-width: 992px) {
    #introCarousel {
      margin-top: -58.59px;
    }
  }
  .navbar{
      padding:30px 0!important;
      border-bottom:1px solid rgba(255,255,255,.08);
  }
  .nav-icon {
      margin-left:20px;
  }
 .navbar .nav-link.active {
     background-color:rgba(#000,.2);
    font-style:italic;
    color:rgba(#fff,.6);
 }
  .navbar .nav-link:hover,
  .navbar-brand:hover{
      -webkit-animation: argh-my-eyes-text .1s infinite;
      -moz-animation:    argh-my-eyes-text .1s infinite;
      animation:         argh-my-eyes-text .1s infinite;
  }
    .navbar .nav-link:hover svg{
        transform-origin: center;
          transform-box: fill-box;
    }
	.navbar-brand strong {
	    font-size: 18px;
	    font-style: italic;
	    visibility: hidden; /* Element stays in the layout, but hidden */
	    opacity: 0;
	    transition: opacity 0.5s ease-in-out;
	    &.show-txt {
	        visibility: visible; /* Element is now visible */
	        opacity: 1; /* Opacity transition happens */
	    }
	}

    .offcanvas.offcanvas-top{
        height:100%;
        opacity: 1

    }

    input[type=text],
    input[type=email]{
        background-color:transparent;
        border-color:rgba(white,.3)!important;
        border-radius:0!important;
        padding-left:0;
        border:none;
        border-bottom:2px solid rgba(white,.35);
        color:white;
        font-family: "Lexend", sans-serif!important;
        padding:.5em .5em .5em 0;
        outline: none;
    }
    textarea{
        background-color:transparent!important;
        border-color:rgba(white,.3)!important;
        border-radius:0!important;
        padding-left:0!important;
        border:none!important;
        border-bottom:2px solid rgba(white,.35)!important;
        color:white!important;
        font-family: "Lexend", sans-serif!important;
        padding:.5em .5em .5em 0!important;
    }
    input[type=text]:focus,
    input[type=email]:focus,
    textarea:focus{
        color:#0cf!important;
        outline-width: 0!important;
        outline: none!important;
         background-color:transparent!important;
        border-bottom:2px solid rgba(#0cf,1)!important;
    }
 